<template>
  <v-app>
    <v-app-bar height="120px" flat fixed>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-img lazy-src="@/assets/emiter_logo.svg" src="@/assets/emiter_logo.svg" width="160px" contain />
          </v-col>
          <v-col class="text-right d-none d-lg-block">
            <v-btn text class="main-menu ma-1" @click="changeSection('id0')">HOME</v-btn>
            <v-btn text class="main-menu ma-1" @click="changeSection('id1')">O NAS</v-btn>
            <v-btn text class="main-menu ma-1" @click="changeSection('id2')">OFERTA</v-btn>
            <v-btn text class="main-menu ma-1" @click="changeSection('id3')">KORZYŚCI WSPÓŁPRACY</v-btn>
            <v-btn text class="main-menu ma-1" @click="changeSection('id4')">KONTAKT</v-btn>
          </v-col>
          <v-col class="d-lg-none">
            <v-btn right icon color="primary" @click="drawer = !drawer">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-img lazy-src="@/assets/logo_C4E.svg" src="@/assets/logo_C4E.svg" width="120px" contain />
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main ref="id0" style="margin-top: 120px">
      <v-navigation-drawer v-model="drawer" fixed left temporary>
        <v-list nav dense>
          <v-list-item-group active-class="green lighten-4 --text text--accent-4">
            <v-list-item @click="changeSection('id0')">
              <v-list-item-title class="main-menu">HOME</v-list-item-title>
            </v-list-item>

            <v-list-item @click="changeSection('id1')">
              <v-list-item-title class="main-menu">O NAS</v-list-item-title>
            </v-list-item>

            <v-list-item @click="changeSection('id2')">
              <v-list-item-title class="main-menu">OFERTA</v-list-item-title>
            </v-list-item>

            <v-list-item @click="changeSection('id3')">
              <v-list-item-title class="main-menu">KORZYŚCI WSPÓŁPRACY</v-list-item-title>
            </v-list-item>

            <v-list-item @click="changeSection('id4')">
              <v-list-item-title class="main-menu">KONTAKT</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-img width="100%" contain lazy-src="@/assets/thumbs/naglowek_emiter_energia_v2.jpg"
        src="@/assets/naglowek_emiter_energia_v2.png">
        <v-container class="fill-height">
          <v-row justify="end" align="baseline">
            <v-col cols="6" class="mb-md-16">
              <v-img lazy-src="@/assets/emiter_logo.svg" src="@/assets/emiter_logo.svg" width="40%" contain
                class="mb-5 mb-md-12" />
              <p class="
                  text-body text-sm-h5 text-md-h4 text-lg-h2
                  emiter--text
                  text-uppercase
                ">
                Zostań naszym partnerem
                <span class="font-weight-bold">w&nbsp;obrocie energią!</span>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-img>

      <v-img cover width="110%" :position="'center right'" lazy-src="@/assets/thumbs/emiter_energia_www_part_2.jpg"
        src="@/assets/emiter_energia_www_part_2.png">
        <v-container ref="id1">
          <v-row>
            <v-col>
              <h2 class="text-right mt-10 text-h5 text-sm-h3 emiter--text d-block">
                O NAS
              </h2>
            </v-col>
          </v-row>
          <v-row align="center" style="height: 70vh; max-height: 700px">
            <v-col class="pa-0">
              <v-row align="end" justify="end" class="fill-height">
                <v-col cols="12" sm="9" md="6" lg="5">
                  <v-card color="rgba(255,255,255,.1)" flat class="pa-5">
                    <h3 class="mb-4 text-h5 text-sm-h3 emiter--text">
                      Emiter Energia
                    </h3>
                    <p class="mb-4 black--text">
                      Dbamy o dostęp do czystej energii i najnowszej technologii
                      dla domu. Jesteśmy dystrybutorem światowej klasy marek z
                      branży fotowoltaiki. Z naszych rozwiązań można stworzyć
                      zarówno mikroinstalacje fotowoltaiczne, jak i większe
                      połączenia stosowane np. w elektrowniach czy dużych
                      przedsiębiorstwach.
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
      <v-row no-gutters style="background-color: #d2ebf2">
        <v-container ref="id2">
          <v-row>
            <v-col>
              <h2 class="text-right mt-10 text-h5 text-sm-h3 emiter--text d-block">
                OFERTA
              </h2>
            </v-col>
          </v-row>

          <v-row no-gutters align="center" justify="center" class="mb-16">
            <p class="absolute-number">1</p>
            <v-col cols="12" sm="6" md="8">
              <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_3_picture_1-07.jpg"
                src="@/assets/emiter_energia_www_part_3_picture_1-07.png" cover max-height="80vh" class="ma-auto" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <h3 class="
                  mb-4
                  text-left text
                  -H3
                  emiter--text
                  text-center text-sm-left
                ">
                Zakup energii przez prosumenta
              </h3>
              <p class="
                  mb-4
                  text-left text
                  -H3
                  black--text
                  text-center text-sm-left
                ">
                Dokonując zakupu energii w Emiter Energia, kwota zawsze będzie
                niższa w porównaniu z lokalnym dostawcą prosumenta.
              </p>
            </v-col>
          </v-row>

          <v-row align="baseline" justify="center" class="mt-16">
            <v-col cols="8" md="3" class="text-center">
              <v-btn elevation="0" fab dark color="#1D4289">1</v-btn>
              <p class="mt-3">
                Skontaktuj się z nami a my polecimy Ci autoryzowanego partnera
              </p>
            </v-col>
            <v-col cols="8" md="3" class="text-center">
              <v-btn elevation="0" fab dark color="#1D4289">2</v-btn>
              <p class="mt-3">
                Zamontuj instalację fotowoltaiczną zakupioną w Emiter i wybierz
                nas, jako dostawcę energii elektrycznej
              </p>
            </v-col>
            <v-col cols="8" md="3" class="text-center">
              <v-btn elevation="0" fab dark color="#1D4289">3</v-btn>
              <p class="mt-3">
                Zarządzaj i wykorzystuj energię jak chcesz i kiedy potrzebujesz
              </p>
            </v-col>
            <v-col cols="8" md="3" class="text-center">
              <v-btn elevation="0" fab dark color="#1D4289">4</v-btn>
              <p class="mt-3">
                Obniżaj swoje rachunki za prąd i ciesz się zieloną energią!
              </p>
            </v-col>
          </v-row>

          <v-row no-gutters align="center" justify="center">
            <p class="absolute-number">2</p>
            <v-col cols="12" sm="6" md="8">
              <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_3_picture_2-07.jpg"
                src="@/assets/emiter_energia_www_part_3_picture_2-07.png" cover max-height="80vh" class="ma-auto" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <h3 class="
                  mb-4
                  text-left text
                  -H3
                  emiter--text
                  text-center text-sm-left
                ">
                Sprzedaż energii przez prosumenta
              </h3>
              <p class="mb-4 text-left black--text text-center text-sm-left">
                Twoja instalacja wytworzyła więcej energii niż potrzeba?
              </p>
              <p></p>
              <p class="mb-4 text-left black--text text-center text-sm-left">
                Odkupimy od Ciebie każdą ilość!
              </p>
              <p class="mb-4 text-left black--text text-center text-sm-left">
                Odkupimy ją od Ciebie po stawce cen godzinowych, zyskasz więc
                więcej, niż przy sprzedaży tej samej energii lokalnemu dostawcy.
              </p>
            </v-col>
          </v-row>

          <v-row align="baseline" justify="center" class="mb-16">
            <v-col cols="8" md="4" align="center">
              <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_3_icon_1-07.jpg"
                src="@/assets/emiter_energia_www_part_3_icon_1-07.png" width="120px" position="center center" />
              <p class="mt-3">
                <strong>Lokalny dostawca energii</strong> <br />
                Odkupi od Ciebie energię po średniej cenie rynkowej (warunki
                niekorzystne)
              </p>
            </v-col>
            <v-col cols="8" md="4" align="center">
              <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_3_icon_2-07.jpg"
                src="@/assets/emiter_energia_www_part_3_icon_2-07.png" width="120px" position="center center" />
              <p class="mt-3">
                <strong>Emiter Energia</strong> <br />
                Odkupimy od Ciebie energię z uwzględnieniem tzw. "pików" cen
                godzinowych (na korzyść klienta)
              </p>
            </v-col>
            <v-col cols="8" md="4" align="center">
              <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_3_icon_3-07.jpg"
                src="@/assets/emiter_energia_www_part_3_icon_3-07.png" width="120px" position="center center" />
              <p class="mt-3">
                <strong>Przewaga na korzyść prosumenta</strong> <br />
                Kwota zostaje wyliczona wg. cen godzinowych tzw. "pików"
                Towarowej Giełdy Energii, dzięki temu każda Twoja MWh może być
                warta nawet 1200 zł!
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <v-container ref="id3">
        <v-row>
          <v-col>
            <h2 class="
                text-right
                mt-16
                mb-16
                text-h5 text-sm-h3
                emiter--text
                d-block
              ">
              KORZYŚCI WSPÓŁPRACY
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row align="baseline" justify="center" class="mb-16">
              <v-col cols="8" md="3" align="center">
                <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_4_icon_1_new.jpg"
                  src="@/assets/emiter_energia_www_part_4_icon_1_new.png" max-width="50%" position="center center" />
                <p class="text-h5 mt-5">
                  ENERGIA W 100% ZE ŹRÓDEŁ ODNAWIALNYCH
                </p>
              </v-col>
              <v-col cols="8" md="3" align="center">
                <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_4_icon_2_new.jpg"
                  src="@/assets/emiter_energia_www_part_4_icon_2_new.png" max-width="50%" position="center center" />
                <p class="text-h5 mt-5">
                  BILANS ENERGII NA POZIOMIE 1:0,7 DLA KLIENTA B2C
                </p>
              </v-col>
              <v-col cols="8" md="3" align="center">
                <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_4_icon_3_new.jpg"
                  src="@/assets/emiter_energia_www_part_4_icon_3_new.png" max-width="50%" position="center center" />
                <p class="text-h5 mt-5">
                  BILANS ENERGII NA POZIOMIE 1:0,95 DLA KLIENTA B2B
                </p>
              </v-col>
              <v-col cols="8" md="3" align="center">
                <v-img lazy-src="@/assets/thumbs/emiter_energia_www_part_4_icon_4_new.jpg"
                  src="@/assets/emiter_energia_www_part_4_icon_4_new.png" max-width="50%" position="center center" />
                <p class="text-h5 mt-5">JASNE I PROSTE WARUNKI ROZLICZENIOWE</p>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="text-center mb-16 mt-10">
              <v-col cols="auto"><span class="text-h3 text-sm-h5">E</span><span
                  class="text-h3 text-sm-h5 emiter--text">KOLOGICZNA</span></v-col>
              <v-col cols="auto" class="text-h3 emiter--text d-none d-sm-block">-</v-col>
              <v-col cols="auto"><span class="text-h3 text-sm-h5">K</span><span
                  class="text-h3 text-sm-h5 emiter--text">ORZYSTNA</span></v-col>
              <v-col cols="auto" class="text-h3 emiter--text d-none d-sm-block">-</v-col>
              <v-col cols="auto"><span class="text-h3 text-sm-h5">O</span><span
                  class="text-h3 text-sm-h5 emiter--text">DNAWIALNA</span></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-img cover lazy-src="@/assets/thumbs/emiter_energia_www_part_5.jpg"
        src="@/assets/emiter_energia_www_part_5.png">
        <v-container ref="id4" class="fill-height">
          <v-row align="center">
            <v-col>
              <v-row>
                <v-col>
                  <h2 class="
                      text-right
                      mt-10
                      text-h5 text-sm-h3
                      emiter--text
                      d-block
                    ">
                    KONTAKT
                  </h2>
                </v-col>
              </v-row>
              <v-row align="baseline" justify="center">
                <v-col cols="12" sm="8" md="4">
                  <p class="text-left -H3 emiter--text">Gdzie jesteśmy?</p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2551.0559393869357!2d19.061181716093056!3d50.25353950941395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716cfc02e39d307%3A0xb35f0f626365689c!2sPorcelanowa%2027%2C%2040-246%20Katowice!5e0!3m2!1spl!2spl!4v1655969637509!5m2!1spl!2spl"
                    width="100%"
                    height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <p class="text-left -H3 emiter--text">Formularz kontaktowy</p>

                  <v-form class="mb-2" ref="form">
                    <v-row cols="12" md="4">
                      <v-col>
                        <label>Imię</label>
                        <v-text-field dense outlined :rules="[(v) => !!v || 'Imię jest wymagane.']" v-model="firstname"
                          background-color="white" hide-details="auto" flat color="#01579B" required></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <label>Nazwisko</label>
                        <v-text-field dense outlined v-model="lastname"
                          :rules="[(v) => !!v || 'Nazwisko jest wymagane.']" background-color="white"
                          hide-details="auto" flat color="#01579B" required></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <label>Telefon</label>
                        <v-text-field dense outlined v-model="phone" :rules="[(v) => !!v || 'Telefon jest wymagany.']"
                          background-color="white" hide-details="auto" flat color="#01579B" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <label>E-mail</label>
                        <v-text-field dense outlined v-model="email" :rules="[(v) => !!v || 'Email jest wymagany.']"
                          background-color="white" hide-details="auto" flat color="#01579B" required></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <label>Nazwa firmy</label>
                        <v-text-field dense outlined v-model="companyName"
                          :rules="[(v) => !!v || 'Nazwa firmy jest wymagana.']" background-color="white"
                          hide-details="auto" flat color="#01579B" required></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <label>Nip</label>
                        <v-text-field dense outlined v-model="nip" :rules="[(v) => !!v || 'NIP jest wymagany.']"
                          background-color="white" hide-details="auto" flat color="#01579B"></v-text-field>
                      </v-col>

                      <v-col>
                        <label>Wiadomość</label>
                        <v-textarea dense outlined v-model="message" :rules="[(v) => !!v || 'Wiadomość jest wymagana.']"
                          background-color="white" hide-details="auto" flat color="#01579B" required></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-checkbox v-model="terms" color="emiter darken-3"
                          :rules="[(v) => !!v || 'Akceptacja regulaminu jest wymagana.']">
                          <template slot="label">
                            <p class="caption">
                              Zgodnie z art. 6 ust. 1 a) ogólnego rozporządzenia
                              o ochronie danych z dnia 27 kwietnia 2016r.
                              wyrażam zgodę na przetwarzanie moich danych
                              osobowych w celach związanych z powyższym
                              zgłoszeniem przez Emiter Sp. Z o.o. Z siedzibą w
                              Katowicach przy ulicy Porcelanowej 27. Podaję dane
                              osobowe dobrowolnie i oświadczam, że są one zgodne
                              z prawdą. Zapoznałem(-am) się z
                              <a @click="rules" target="_blank"
                                href="https://www.emiter.net.pl/sites/default/files/pliki-rozne/polityka_ochrony_danych_osobowych.pdf">treścią
                                polityki prywatności</a>
                              firmy, w tym z informacją o celu przetwarzania
                              danych osobowych oraz prawie dostępu do treści
                              swoich danych i prawie ich poprawienia. Rozumiem,
                              że w każdym momencie mogę wycofać swoją zgodę, a
                              wycofanie zgody nie wpływa na zgodność z prawem
                              przetwarzania, którego dokonano wcześniej.
                            </p>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row class="mb-16">
                      <v-col align="right">
                        <v-alert type="error" v-model="isError" dismissible>
                          <div v-html="error"></div>
                        </v-alert>
                        <v-alert type="success" v-model="success" dismissible>
                          Twoja wiadomość została wysłana.
                        </v-alert>
                        <v-btn elevation="0" color="#616161" dark :loading="loading" @click="sendMail">
                          wyślij wiadomość
                        </v-btn>
                        <p class="mt-16">
                          Nasz adres email:
                          <a class="text-decoration-none"
                            href="mailto:biuro@emiter-energia.com">biuro@emiter-energia.com</a>
                        </p>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-img>

      <v-footer color="#616161">
        <v-row no-gutters align="center" justify="center" justify-sm="space-between" class="white--text">
          <v-col cols="12" sm="auto">
            <v-img lazy-src="@/assets/logo_emiter_energia_white.svg" src="@/assets/logo_emiter_energia_white.svg"
              width="120px" contain class="ma-auto" />
          </v-col>

          <v-col cols="auto" class="white--text text-center text-sm-right mt-5 mb-5">
            <span> ul. Porcelanowa 27, 40-241 Katowice </span>
            <br />
            <span class="d-none">|</span>

            <span>
              <a href="tel:327303400" class="white--text text-decoration-none">
                tel. 32 730 34 00 </a>,
              <a href="tel:+48691459493" class="white--text text-decoration-none">
                tel. +48 691 459 493
              </a>
            </span>
            <br />
            <span class="d-none">|</span>

            <span>
              <a href="biuro@emiter-energia.com" class="white--text text-decoration-none">
                biuro@emiter-energia.com
              </a>
            </span>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  name: "App",

  // components: {
  //   CustomMenu,
  // },

  data: () => ({
    drawer: false,
    terms: false,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    companyName: "",
    nip: "",
    message: "",
    error: null,
    isError: false,
    success: false,
    loading: false,
  }),

  methods: {
    rules: function (e) {
      e.stopPropagation();
    },
    changeSection: function (section) {
      this.drawer = false;
      const ref = this.$refs[section];
      if (ref) {
        if (ref instanceof HTMLElement) {
          ref.scrollIntoView({ behavior: "smooth" });
        } else {
          ref.$el.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    sendMail: function () {
      if (this.$refs.form.validate()) {
        const params = new FormData();
        params.append("from", this.firstname + " " + this.lastname);
        params.append("email", this.email);
        params.append("phone", this.phone);
        params.append("companyName", this.companyName);
        params.append("nip", this.nip);
        params.append("message", this.message);
        this.loading = true;
        axios
          .post("/php/mail.php", params)
          .then(() => {
            this.success = true;
          })
          .catch(({ response }) => {
            this.isError = true;
            this.error = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,500;1,200&display=swap");

.emiter--text {
  color: #1d4289;
}

* {
  font-family: "Montserrat", sans-serif;
}

.main-menu {
  /*color: rgb(0, 0, 0);*/
  color: black;
  text-decoration: none;
  font-weight: bolder;
  font-style: normal;
}

.absolute-number {
  z-index: 2;
  opacity: 0.9;
  position: absolute;
  font-size: 150px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 600px) {
  .absolute-number {
    left: 25%;
  }
}

@media only screen and (min-width: 960px) {
  .absolute-number {
    left: 50%;
  }
}
</style>
